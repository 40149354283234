import { Form, Select } from "antd";
import { ProForm } from "@ant-design/pro-components";

const PaymentMethod = () => {
  return (
    <>
      <h1
        style={{
          marginBottom: 15,
        }}
      >
        Способ оплаты
      </h1>
      <ProForm.Group>
        <Form.Item
          name="paymentMethod"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Способ оплаты"
            options={[
              {
                label: "Kaspi Pay",
                value: "KASPI_PAY",
              },
              {
                label: "Наличные",
                value: "CASH",
              },
              {
                label: "Счёт на оплату",
                value: "AN_INVOICE_FOR_PAYMENT",
              },
              {
                label: "Kaspi рассрочка",
                value: "KASPI_INSTALLMENT_PLAN",
              },
              {
                label: "Halyk Bank",
                value: "HALYK_BANK",
              },
              {
                label: "Другие банки",
                value: "OTHER_BANKS",
              },
              {
                label: "Kaspi и другие банки",
                value: "Kaspi_OtherCards",
              },
              
            ]}
          />
        </Form.Item>
      </ProForm.Group>
      <p
        style={{
          color: "rgb(133, 133, 133, 0.5)",
          fontSize: 12,
        }}
      >
        Способ оплаты
      </p>
    </>
  );
};

export default PaymentMethod;

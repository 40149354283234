import {
  ProForm,
  ProFormCheckbox,
  ProFormDigit,
  ProFormDigitRange,
  ProFormGroup,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { Button, Card, Switch } from "antd";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import { DeleteNotification } from "../../ClientInfo/ClientsPopover";
import { deleteProduct, updateProduct } from "../utils/fetch";

const FullProductInfo = ({ data }) => {
  const [readonly, setReadonly] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedAccessories, setSelectedAccessories] = useState([]);

  const [form] = useForm();

  const handleDelete = () => {
    deleteProduct(data._id);
  };

  const handleSave = (value) => {
    const body = {
      name: value.name,
      costPrice: value.costPrice,
      price: value.price,
      description: value.description,
      ...(data.doorType && {
        minWidth: value.width ? value.width[0] : null,
        maxWidth: value.width ? value.width[1] : null,
        minHeight: value.height ? value.height[0] : null,
        maxHeight: value.height ? value.height[1] : null,
        thickness: value.thickness,
        doorStyle: value.doorStyle,
        isSlidingDoor: value.isSlidingDoor,
        doorCovering: value.doorCovering,
      }),
      accessories: selectedAccessories,
    };
  
    updateProduct(data._id, body);
  };

  return (
    <Card>
      {showDelete && (
        <DeleteNotification
          content={
            <>
              <p>Вы точно хотите удалить товар?</p>
              <p>
                <b>{data.name}</b>
              </p>
            </>
          }
          onClose={() => setShowDelete(false)}
          onSubmit={handleDelete}
        />
      )}
      <Switch
        style={{
          marginBottom: 16,
        }}
        checked={readonly}
        checkedChildren="Ред."
        unCheckedChildren="Просм."
        onChange={setReadonly}
      />
      <ProForm
        form={form}
        readonly={readonly}
        name="validate_other"
        initialValues={{
          width: data.doorType ? [data.minWidth, data.maxWidth] : undefined,
          height: data.doorType ? [data.minHeight, data.maxHeight] : undefined,
          name: data.name || '',
          price: data.price || 0,
          costPrice: data.costPrice || 0,
          description: data.description || '',
          thickness: data.doorType ? data.thickness : undefined,
          doorStyle: data.doorType ? data.doorStyle : undefined,
          isSlidingDoor: data.doorType ? data.isSlidingDoor : undefined,
          doorCovering: data.doorType ? data.doorCovering : undefined,
        }}
        onFinish={(value) => handleSave(value)}
        submitter={{
          render: () => {
            return [];
          },
        }}
      >
        <ProFormGroup>
          <ProFormText
            width="lg"
            name="name"
            label="Наименование"
            allowClear={false}
            placeholder="Наименование"
            rules={[
              {
                required: true,
                message: "Обязательное поле",
              },
            ]}
          />
        </ProFormGroup>
        {data.doorType && (
          <div>
            <div
              style={{
                display: "flex",
              }}
            >
              <ProFormGroup label="Высота">
                <ProFormDigitRange
                  placeholder={"Высота"}
                  name="height"
                  rules={[
                    {
                      required: true,
                      message: "Обязательное поле",
                    },
                  ]}
                />
              </ProFormGroup>
              <div style={{ width: 30 }}></div>
              <ProFormGroup
                label="Ширина"
                style={{
                  marginRight: 30,
                }}
              >
                <ProFormDigitRange
                  placeholder={"Ширина"}
                  name="width"
                  rules={[
                    {
                      required: true,
                      message: "Обязательное поле",
                    },
                  ]}
                />
              </ProFormGroup>
            </div>
            <div className="d-flex">
              <ProFormGroup
                style={{
                  marginRight: 10,
                }}
              >
                <ProFormSelect
                  allowClear={false}
                  label="Толщина"
                  name="thickness"
                  placeholder="Толщина"
                  rules={[
                    {
                      required: true,
                      message: "Обязательное поле",
                    },
                  ]}
                  options={[
                    {
                      value: 42,
                    },
                    {
                      value: 57,
                    },
                  ]}
                />
              </ProFormGroup>
              <ProFormGroup
                style={{
                  marginRight: 10,
                }}
              >
                <ProFormSelect
                  allowClear={false}
                  label="Вид двери"
                  name="doorStyle"
                  placeholder="Толщина"
                  rules={[
                    {
                      required: true,
                      message: "Обязательное поле",
                    },
                  ]}
                  options={[
                    {
                      value: "SINGLE_DOORS",
                      label: "Одностворчатые",
                    },
                    {
                      value: "DOUBLE_DOORS",
                      label: "Двустворчатые",
                    },
                  ]}
                />
              </ProFormGroup>
            </div>
            <div>
              {" "}
              <ProFormGroup
                style={{
                  marginRight: 10,
                }}
              >
                <ProFormSelect
                  allowClear={false}
                  label="Покрытие двери"
                  name="doorCovering"
                  placeholder="Покрытие двери"
                  rules={[
                    {
                      required: true,
                      message: "Обязательное поле",
                    },
                  ]}
                  options={[
                    {
                      value: "PRIMED",
                      label: "Грунтованные",
                    },
                    {
                      value: "VENEERED",
                      label: "Шпонированные",
                    },
                    {
                      value: "MIRROR",
                      label: "Зеркальные",
                    },
                    {
                      value: "PAINTED",
                      label: "Крашенные",
                    },
                  ]}
                />
              </ProFormGroup>
            </div>
            <div>
              <ProFormGroup>
                <ProFormCheckbox name="isSlidingDoor">
                  Раздвижной дверь
                </ProFormCheckbox>
              </ProFormGroup>
            </div>
          </div>
        )}
        <div className="d-flex">
          <ProFormGroup
            style={{
              marginRight: 10,
            }}
          >
            <ProFormDigit
              label="Цена"
              name="price"
              placeholder="Цена"
              rules={[
                {
                  required: true,
                  message: "Обязательное поле",
                },
              ]}
            />
          </ProFormGroup>
          <ProFormGroup>
            <ProFormDigit
              label="Себестоимость"
              name="costPrice"
              placeholder="Себестоимость"
              rules={[
                {
                  required: true,
                  message: "Обязательное поле",
                },
              ]}
            />
          </ProFormGroup>
        </div>
        <ProFormGroup>
          <ProFormTextArea
            label="Примечание"
            name="description"
            placeholder="Примечание"
          />
        </ProFormGroup>
        {!readonly && (
          <ProFormGroup
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              style={{
                backgroundColor: "red",
                color: "white",
              }}
              onClick={() => {
                setShowDelete(true);
              }}
            >
              Удалить
            </Button>
            <Button
              onClick={() => {
                setReadonly(true);
                form.resetFields();
                setSelectedAccessories(
                  data?.accessories.map((a) => a._id) || []
                );
              }}
            >
              Отменить
            </Button>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </ProFormGroup>
        )}
      </ProForm>
    </Card>
  );
};

export default FullProductInfo;

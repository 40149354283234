import { ProDescriptions } from "@ant-design/pro-components";

const { Item } = ProDescriptions;

const ClientFullInfo = ({ client }) => {
  const getClientTypeLabel = (clientType) => {
    if (clientType === "ENTITY") {
      return "Юридическое лицо";
    }

    return "Физическое лицо";
  };

  const getClientInfoByType = (client) => {
    if (client.clientType === "ENTITY") {
      return (
        <>
          <Item label="Наименование компании" valueType="text">
            <b>{client.companyName}</b>
          </Item>
          <Item label="Фамилия и инициалы директора" valueType="text">
            <b>{client.fullNameDirector}</b>
          </Item>
          <Item label="На каком основании является директором" valueType="text">
            <b>{client.onWhatBasisDirector}</b>
          </Item>
          <Item label="БИН" valueType="text">
            <b>{client.BIN}</b>
          </Item>
          <Item label="Адрес" valueType="text">
            <b>{client.legalAddress}</b>
          </Item>
          <Item label="Номер счета" valueType="text">
            <b>{client.accountNumber}</b>
          </Item>
          <Item label="Наименование банка" valueType="text">
            <b>{client.bankName}</b>
          </Item>
          <Item label="БИК" valueType="text" span={2}>
            <b>{client.BIC}</b>
          </Item>
        </>
      );
    }

    return (
      <>
        <Item label="ФИО" valueType="text">
          <b>{client.name}</b>
        </Item>
        <Item label="ИИН" valueType="text">
          <b>{client.iin}</b>
        </Item>
      </>
    );
  };

  return (
    <ProDescriptions column={2}>
      <Item label="Тип клиента" ellipsis span={2} valueType="text">
        <b>{getClientTypeLabel(client.clientType)}</b>
      </Item>
      {getClientInfoByType(client)}
      <Item label="Номер телефона">
        <b>{client.phone}</b>
      </Item>
      <Item label="Дата регистрации">
        <b>{new Date(client.createdAt).toLocaleDateString()}</b>
      </Item>
    </ProDescriptions>
  );
};

export default ClientFullInfo;

export const getTotalPrice = (selectedProducts) => {
  if (!Array.isArray(selectedProducts) || selectedProducts.length === 0) {
    return 0;
  }

  let totalPrice = 0;
  for (let product of selectedProducts) {
    const p = product.product;
    const price = product.price || (p && p.price) || 0;
    const amount = product.amount || 1;

    totalPrice += price * amount;
  }

  return totalPrice;
};

export const getTotalPriceWithDiscount = (selectedProducts, priceIncrease = 0, discount = 0) => {
  if (!Array.isArray(selectedProducts) || selectedProducts.length === 0) {
    return 0;
  }

  let totalPrice = 0;

  for (let product of selectedProducts) {
    const p = product.product;
    let price = product.price || (p && p.price) || 0;
    const amount = product.amount || 1;
    const orderDiscount = product.orderDiscount || 0;

    price *= amount;

    if (product.aluminium === "BLACK") {
      price *= 1;
    }

    const appreciationValue = Math.round(price * ((product.appreciationPercentage || 0) / 100));
    price += appreciationValue;

    if (orderDiscount > 0) {
      price -= Math.round(price * (orderDiscount / 100));
    } else {
      price -= Math.round(price * (discount / 100));
    }

    totalPrice += price;
  }

  return totalPrice;
};

export const currencyFormat = (num, minimumFractionDigits = 0) => {
  if (num == null || Number.isNaN(parseInt(num))) {
    return "0";
  }

  try {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits,
    });
    return formatter.format(num).replace(/,/g, " ");
  } catch (e) {
    return "0";
  }
};

export const getOrderPriceWithDiscountAndAppreciationPercentage = (productPrice, discount = 0, appreciationPercentage = 0) => {
  if (!productPrice || isNaN(productPrice)) {
    return 0;
  }

  const appreciationValue = Math.round(productPrice * (appreciationPercentage / 100));
  const priceWithAppreciationPercentage = productPrice + appreciationValue;
  const discountValue = Math.round(priceWithAppreciationPercentage * (discount / 100));
  const resultWithDiscount = priceWithAppreciationPercentage - discountValue;

  return resultWithDiscount;
};